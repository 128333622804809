import { addDays } from "date-fns";
import { Override } from "../types";
import { ObjectEnum } from "../types/enums";
import { dateToStr, strToDate } from "../utils/dates";
import { camelcase } from "../utils/strings";
import {
  DayHours as DayHoursDto,
  LocalTimeInterval as LocalTimeIntervalDto,
  ReclaimSku as ReclaimSkuDto,
  TimePolicy as TimePolicyDto,
  User as UserDto,
  UserSettings as UserSettingsDto,
  UserTrait as UserTraitDto,
} from "./client";
import { EventColor, PrimaryCategory } from "./EventMetaTypes";
import { TaskDefaults } from "./Tasks";
import { TransformDomain } from "./types";

export const LOGIN_BASE_URI = process.env.NEXT_PUBLIC_LOGIN_BASE_URI || "/oauth/login";
export const LOGOUT_URI = process.env.NEXT_PUBLIC_LOGOUT_URI || "/logout";

export class UserTrait extends ObjectEnum<UserTraitDto> {
  constructor(public readonly group: string, public readonly feature: string, public readonly label: string) {
    super([group, feature].join("_") as UserTraitDto);
  }
}
export class InterestTrait extends UserTrait {
  static Tasks = new InterestTrait("TASKS", "Tasks");
  static Priorities = new InterestTrait("PRIORITIES", "Priorities");
  static Office365 = new InterestTrait("OFFICE365", "Office365");
  static Calendar = new InterestTrait("CALENDAR", "Calendar");

  static Asana = new InterestTrait("INTEGRATION_ASANA", "Asana");
  static Trello = new InterestTrait("INTEGRATION_TRELLO", "Trello");
  static Todoist = new InterestTrait("INTEGRATION_TODOIST", "Todoist");
  static Jira = new InterestTrait("INTEGRATION_JIRA", "Jira");
  static Linear = new InterestTrait("INTEGRATION_LINEAR", "Linear");
  static ClickUp = new InterestTrait("INTEGRATION_CLICKUP", "ClickUp");
  static Monday = new InterestTrait("INTEGRATION_MONDAY", "Monday");

  static get(feature: string) {
    if (!feature) return undefined;
    return super.get(`INTEREST_${feature.toUpperCase()}`);
  }

  constructor(public readonly feature: string, public readonly label: string) {
    super("INTEREST", feature, label);
  }
}
export class OnboardTrait extends UserTrait {
  static Tasks = new OnboardTrait("TASKS", "Tasks");
  static GoogleTasks = new OnboardTrait("GOOGLE_TASKS", "Google Tasks");
  static PlanItemPrioritized = new OnboardTrait("PLAN_ITEM_PRIORITIZED", "Plan item prioritized");
  static SmartOneOnOne = new OnboardTrait("SMART_ONE_ON_ONES", "Smart 1:1s");
  static BufferTime = new OnboardTrait("BUFFER_TIME", "Buffer time");
  static TasksReindex = new OnboardTrait("TASKS_REINDEX", "Tasks reindex");

  static get(feature: string) {
    if (!feature) return undefined;
    return super.get(`ONBOARD_${feature.toUpperCase()}`);
  }

  static completed(user: User | null, feature: OnboardTrait) {
    return !!user?.features.onboard?.[camelcase(feature.feature)];
  }

  constructor(public readonly feature: string, public readonly label: string) {
    super("ONBOARD", feature, label);
  }
}

export enum TimePolicyType {
  Work = "WORK",
  Personal = "PERSONAL",
  Meeting = "MEETING",
}

export class ReclaimSku extends ObjectEnum {
  static Free = new ReclaimSku(ReclaimSkuDto.ASSISTANT, "Free");
  static Pro = new ReclaimSku(ReclaimSkuDto.PRO, "Pro");
  static Team = new ReclaimSku(ReclaimSkuDto.TEAM, "Team");
  static Trial = new ReclaimSku(ReclaimSkuDto.TRIAL, "Trial");

  constructor(public readonly key: ReclaimSkuDto, public readonly label: string) {
    super(key);
  }

  static get Options(): ReclaimSku[] {
    return Object.values(ReclaimSku);
  }
}

export type DayHours = DayHoursDto;
export type LocalTimeInterval = LocalTimeIntervalDto;
export type TimePolicy = Override<
  TimePolicyDto,
  {
    // startOfWeek?: DayOfWeek;
    // endOfWeek?: DayOfWeek;
    // dayHours: Record<DayOfWeek, DayHours>;
  }
>;

export type AssistSettings = Override<
  UserSettingsDto["assistSettings"],
  {
    travel?: boolean;
    otherTravelDuration?: number;
    conferenceBuffer?: boolean;
    conferenceBufferDuration?: number;
    conferenceBufferPrivate?: boolean;
    focus?: boolean;
  }
>;

export type SlackSettings = Override<
  UserSettingsDto["slackSettings"],
  {
    readonly enabled: boolean;
  }
>;

export type TaskSettings = Override<
  UserSettingsDto["taskSettings"],
  {
    readonly enabled: boolean;
    readonly googleTasks: boolean;
    defaults: TaskDefaults;
  }
>;

export type ProjectSettings = Override<
  UserSettingsDto["projects"],
  {
    readonly enabled: boolean;
  }
>;

export type PrioritiesSettings = Override<
  UserSettingsDto["priorities"],
  {
    readonly enabled: boolean;
  }
>;

export type ColorsSettings = Override<
  UserSettingsDto["colors"],
  {
    readonly enabled: boolean;
    readonly categoriesEnabled: boolean;
    readonly projectsEnabled: boolean;
    categories: Record<string, EventColor>;
    priorities: Record<string, EventColor>;
  }
>;

export type CalendarSettings = Override<
  UserSettingsDto["calendar"],
  {
    readonly enabled: boolean;
  }
>;

export type FocusSettings = Override<
  UserSettingsDto["focus"],
  {
    readonly enabled: boolean;
  }
>;

export type SyncSettings = Override<
  UserSettingsDto["sync"],
  {
    readonly enabled: boolean;
  }
>;

export type WeeklyReportSettings = Override<
  UserSettingsDto["weeklyReport"],
  {
    readonly enabled: boolean;
    sendReport?: boolean;
  }
>;

export type UserSettings = Override<
  UserSettingsDto,
  {
    assistSettings: AssistSettings;
    slackSettings: SlackSettings;
    taskSettings: TaskSettings;
    projectSettings: ProjectSettings;
    priorities: PrioritiesSettings;
    colors: ColorsSettings;
    calendar: CalendarSettings;
    focus: FocusSettings;
    sync: SyncSettings;
    weeklyReport: WeeklyReportSettings;
    timePolicies: Record<string, TimePolicy>; // FIXME (IW): use TimePolicyType as key
  }
>;

export type UserFeature = keyof UserSettings;

// TODO this is going to be kinda messy to maintain... to keep in sync with UserSettings (ma)
export type UserFeatureFlag = {
  modifyCalendar: boolean;
  assist: boolean;

  "defaultSyncSettings.workingHours": boolean;

  "assistSettings.focus": boolean;
  "assistSettings.travel": boolean;
  "assistSettings.conferenceBuffer": boolean;
  "assistSettings.conferenceBufferPrivate": boolean;

  "taskSettings.enabled": boolean;
  "slackSettings.enabled": boolean;
  "priorities.enabled": boolean;

  "colors.enabled": boolean;
  "colors.prioritiesEnabled": boolean;
  "colors.categoriesEnabled": boolean;
  "colors.projectsEnabled": boolean;

  "calendar.enabled": boolean;
  "focus.enabled": boolean;
  "billing.enabled": boolean;

  "appNotifications.enabled": boolean;
  "appNotifications.unscheduledPriority": boolean;

  "weeklyReport.sendReport": boolean;
};

export type UserTimezone = {
  id: string;
  displayName: string;
  abbreviation: string;
};

export type User = Override<
  UserDto,
  {
    readonly id: UserDto["id"];
    readonly principal: UserDto["principal"];
    readonly provider: UserDto["provider"];
    readonly email: UserDto["email"];
    readonly timezone: UserTimezone;

    readonly trackingCode: UserDto["trackingCode"];
    readonly refCode: UserDto["refCode"];

    readonly admin?: UserDto["admin"];
    readonly syncUser?: UserDto["syncUser"];
    readonly likelyPersonal?: UserDto["likelyPersonal"];
    readonly apiKey?: UserDto["apiKey"];

    readonly created?: Date;
    readonly deleted?: Date;

    readonly sku: ReclaimSku;

    features: UserSettings;

    // TODO (ma): backend is generating incorrect types for response
    locale?: string;
  }
>;

function dtoToUser(dto: UserDto): User {
  const taskSettings: TaskSettings = {
    ...dto.features?.taskSettings,
    defaults: {
      ...dto.features?.taskSettings?.defaults,
      category: PrimaryCategory.get(dto.features?.taskSettings?.defaults?.category as unknown as string),
    },
  } as TaskSettings;

  const user: User = {
    ...dto,
    features: { ...(dto.features as unknown as UserSettings), taskSettings },
    created: strToDate(dto.created),
    deleted: strToDate(dto.deleted),
    sku: ReclaimSku.get(dto.sku) || ReclaimSku.Free,
  } as User;

  // colors
  if (!!dto.features?.colors) {
    const colors: Partial<ColorsSettings> = Object.entries(dto.features.colors).reduce((acc, [property, group]) => {
      if (typeof group === "object") {
        acc[property] = Object.entries<string>(group).reduce((acc2: Record<string, EventColor>, [color, value]) => {
          acc2[color] = EventColor.get(value);
          return acc2;
        }, {});
      }
      return acc;
    }, {});

    user.features.colors = { ...user.features.colors, ...colors };
  }

  return user;
}

function userToDto(user: Partial<User>): Partial<UserDto> {
  // TODO (IW): Fix nested types so this doesn't have to be casted as `any`
  const dto: Partial<UserDto> = {
    ...user,
    features: user.features as unknown as UserSettingsDto,
    created: dateToStr(user.created),
    deleted: dateToStr(user.deleted),
    // TODO (IW): Figure out readonly annotations, don't serialize readonly stuff
    locale: undefined,
    sku: user.sku?.key || undefined,
  };

  return dto;
}

export class UsersDomain extends TransformDomain<User, UserDto> {
  resource = "User";
  cacheKey = "users";
  pk = "id";

  public deserialize = dtoToUser;
  public serialize = userToDto;

  getCurrentUser = this.manageErrors(this.deserializeResponse(this.api.users.current));

  updateCurrentUser = this.manageErrors(
    this.deserializeResponse((user: Partial<User>) => this.api.users.patch4(this.serialize(user) as UserDto))
  );

  deleteCurrentUser = this.manageErrors(this.api.users.delete6);

  addInterest = this.manageErrors(
    this.deserializeResponse((trait: InterestTrait) => this.api.users.addTrait(trait.key, {}))
  );

  listContacts = this.manageErrors(this.api.users.getContacts);

  inviteContacts = this.manageErrors(this.api.users.inviteContacts);

  referrals = this.manageErrors(this.api.users.referrals);

  /**
   * MOCK
   */
  trialExpirationDate = (() => {
    const expiration = addDays(new Date(), Math.round(Math.random() * 10) + 1).getTime();
    return () => new Promise((res) => setTimeout(res, 500)).then(() => expiration);
  })();

  authRedirect(
    provider: string,
    hint?: string | false,
    state?: { [key: string]: string | null | number | undefined } | null
  ) {
    // FIXME: (IW) Doesn't work when base uri is just a path (eg. '/oauth/login'),
    // but window.location is no bueno
    const authUrl = new URL(`${LOGIN_BASE_URI}/${provider}`, window.location.href);
    if (state) authUrl.searchParams.append("state", JSON.stringify(state));

    if (false === hint) {
      authUrl.searchParams.append("prompt", "select_account");
    } else if (typeof hint === "string") {
      authUrl.searchParams.append("login_hint", hint);
    }

    window.location.href = authUrl.toString();
  }

  logout() {
    const url = new URL(LOGOUT_URI, window.location.href);
    window.location.href = url.toString();
  }
}
